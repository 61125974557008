import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import { useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import { themeGet } from 'styled-system';
import queryString from 'query-string'
import Reaptcha from 'reaptcha';
import ReactGA from 'react-ga'
import { useLocation } from "@reach/router"
import Sticky from 'react-stickynode';

import { ResetCSS } from 'common/src/assets/css/style';
import { GlobalStyle, ContentWrapper } from '../containers/Saas/saas.style';
import Navbar from '../containers/Saas/Navbar';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import Text from 'common/src/components/Text';
import Input from 'common/src/components/Input';
import Button from 'common/src/components/Button';
import Heading from 'common/src/components/Heading';
import { saasTheme } from 'common/src/theme/saas';
import useForm from '../components/useForm'
import useScript from '../components/useScript'

import PricingTable, {
  PricingHead,
  PricingPrice,
  PricingList,
  ListItem,
} from '../containers/Saas/PricingSection/pricing.style';

import { checkmark } from 'react-icons-kit/icomoon/checkmark';
import Icon from 'react-icons-kit';

const GlobalStyle1 = createGlobalStyle`
.g-recaptcha > div {
  margin: 0 auto 15px !important;
 }

.fullWidth {
  width: 100%;
}
`

const nameStyle = {
  fontSize: ['28px', '28px', '30px', '30px', '30px'],
  fontWeight: '600',
  color: 'headingColor',
  letterSpacing: '-0.025em',
  textAlign: 'center',
  mb: '32px',
}

const descriptionStyle = {
  fontSize: ['15px', '16px', '16px', '16px', '16px'],
  color: 'textColor',
  lineHeight: '1.75',
  textAlign: 'center',
  mb: '32px',
}

let PayPalButton = null

const RegisterForm = ({ plan, onRegister, onValidate }) => {
  const validator = (values) => {
    const errors = {}
    if (!values.name) {
      errors.name = "Required"
    }

    if (!values.email) {
      errors.email = "Required"
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = 'Email address is invalid';
    }

    if (!values.password) {
      errors.password = "Required"
    }

    if (!values.confirm_password) {
      errors.confirm_password = "Required"
    } else if (values.password !== values.confirm_password) {
      errors.confirm_password = "Passwords not the same"
      errors.password = "Passwords not the same"
    }

    return errors
  }

  const { values, errors, handleChange } = useForm(null, validator)
  const [alreadyRegistered, setAlreadyRegistered] = useState(null)
  const [submitting, setSubmitting] = useState(false)
  var captcha = null
  const [captchaToken, setCaptchaToken] = useState(null)
  const [paypalLoaded, paypalError] = useScript(`https://www.paypal.com/sdk/js?client-id=${process.env.PAYPAL_CLIENT_ID}&vault=true&intent=subscription`)
  const [ppCount, setPpCount] = useState(0)

  useEffect(() => {
    if (paypalLoaded && !paypalError) {
      PayPalButton = window.paypal.Buttons.driver('react', { React, ReactDOM })
      setPpCount(p => p + 1)
    } else {
      PayPalButton = null
      setPpCount(p => p + 1)
    }
  }, [paypalLoaded, paypalError])

  const handleSubmit = event => {
    event.preventDefault()
  }

  const register = async (payment) => {
    try {
      var formData = new FormData();
      formData.append('name', values.name)
      formData.append('email', values.email)
      formData.append('password', values.password)
      formData.append('plan', plan.name)
      captchaToken && formData.append('captcha', captchaToken)
      payment && formData.append('payment', payment)

      setSubmitting(true)

      const r = await fetch(process.env.FTECH_API_URL + '/register', {
        method: 'POST',
        body: formData,
      });

      if (r.ok) {
        if (onRegister) {
          onRegister(values.email)
        }
      } else {
        ReactGA.event({
          category: 'register-error',
          action: `${r.statusText},n=${values.name},u=${values.email},s=${plan.name},p=${payment}`
        })
        if (onRegister) {
          onRegister(new Error(values.email))
        }
      }
      setSubmitting(false)
    } catch (err) {
      setCaptchaToken(null)
      console.dir(err)
      ReactGA.event({
        category: 'register-error',
        action: `${err.message},n=${values.name},u=${values.email},s=${plan.name},p=${payment}`
      })
      if (onRegister) {
        onRegister(new Error(values.email))
      }
      setSubmitting(false)
    }
  }

  const formSubmit = async () => {
    register()
  }

  const handleInputChange = name => value => {
    captcha.reset()
    handleChange(name, value)
  }
  const handleEmailChange = name => value => {
    captcha.reset()
    setAlreadyRegistered(null)
    handleChange(name, value)
  }

  const handleEmailBlur = event => {
    setAlreadyRegistered(null)
    if (onValidate) {
      onValidate(values).then(res => {
        if (!res.ok) {
          setAlreadyRegistered('This e-mail is already registered. Please choose another one.')
        }
      }).catch(err => {
        ReactGA.event({
          category: 'validate-error',
          action: err.message
        })
      })
    }
  }

  const onCaptchaVerify = token => {
    setCaptchaToken(token)
  }
  const onCaptchaError = err => {
    captcha.reset()
    setCaptchaToken(null)
  }
  const onCaptchaExpire = () => {
    captcha.reset()
    setCaptchaToken(null)
  }

  return (
    <form onSubmit={handleSubmit}>
      <Input isMaterial label="Name" onChange={handleInputChange("name")} tabIndex="1" />
      <small className="input_error">{errors.name}</small>

      <Input inputType="email" isMaterial label="Email" onChange={handleEmailChange("email")} onBlur={handleEmailBlur} tabIndex="2" />
      <small className="input_error">{errors.email || alreadyRegistered}</small>

      <Input inputType="password" isMaterial label="Password" passwordShowHide tabIndex="3" onChange={handleInputChange("password")} />
      <small className="input_error">{errors.password}</small>

      <Input inputType="password" isMaterial label="Confirm Password" passwordShowHide tabIndex="4" onChange={handleInputChange("confirm_password")} />
      <small className="input_error">{errors.confirm_password}</small>

      <Reaptcha
        ref={e => (captcha = e)}
        sitekey={process.env.RECAPTCHA_SITE_API_KEY}
        onVerify={onCaptchaVerify}
        onError={onCaptchaError}
        onExpire={onCaptchaExpire}
      />

      <div className={(Object.keys(errors).length > 0 || alreadyRegistered || !captchaToken) ? "confirm_disable" : null}>
        {plan.prodPlanId ?
          PayPalButton && <PayPalButton
            onClick={(data, actions) => {
              return onValidate(values)
                .then(res => {
                  if (res.ok) {
                    return actions.resolve()
                  }
                  ReactGA.event({
                    category: 'validate-registered',
                    action: values.email
                  })
                  setAlreadyRegistered('This e-mail is already registered. Please choose another one.')
                  return actions.reject()
                }).catch(err => {
                  console.log(err)
                  setCaptchaToken(null)
                  ReactGA.event({
                    category: 'validate-error',
                    action: err.message
                  })
                  return actions.reject()
                })
            }}
            createSubscription={(data, actions) => {
              return actions.subscription.create({
                auto_renewal: true,
                plan_id: process.env.NODE_ENV === "production" ? plan.prodPlanId : plan.testPlanId,
              });
            }}
            onApprove={async (data, actions) => {
              console.log(data, actions)
              const subscription = await actions.subscription.get()
              register(subscription.id)
              // return actions.subscription.activate();
            }}
            onError={err => {
              console.log(err)
            }}
          />
          : <Button onClick={formSubmit} className="default fullWidth" title="REGISTER" disabled={submitting} />}
      </div>
    </form>
  )
}

const ButtonWrapper = styled.div`
  margin-top: 20px;
`

export default () => {
  const Data = useStaticQuery(graphql`
    query {
      saasJson {
        MONTHLY_PRICING_TABLE {
          name
          price
          priceLabel
          description
          buttonLabel
          url
          freePlan
          testPlanId
          prodPlanId
          listItems {
            content
          }
        }
      }
      BackgroundImage : file(relativePath: { eq: "image/saas/saas-banner.jpg" }) {
        childImageSharp {
          fluid (maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const location = useLocation()
  const query = location.search && queryString.parse(location.search)
  const plan = Data.saasJson.MONTHLY_PRICING_TABLE.find(p => p.name === query.plan)
  if (!plan) return null

  const [state, setState] = useState({ state: 'form' })

  const handleRegister = (msg) => {
    if (msg instanceof Error) {
      setState({
        state: 'error', content: <>
          <h2 style={{ color: 'red' }}><b>Error!</b></h2>
          <p>
            We appologize but some error occured during your registration.<br />
            <br />
            We have been notified about this issue, and will inform you about registration status at <b style={{ color: 'black' }}>{msg.message}</b>.<br />
            <br />
            Do not hesitate to <a href="/contact-us">Contact Us</a>.
          </p>
        </>
      })
    } else {
      setState({
        state: 'success', content: <>
          <h2>Congratulations!</h2>
          <p>
            Your registration for <b style={{ color: 'black' }}>{plan.name}</b> is successfull.<br />
            We will contact you at <b style={{ color: 'black' }}>{msg}</b>, for automatic <b>Insurer</b> orders import setup.<br />
            <br />
            Do not hesitate to <a href="/contact-us">Contact Us</a>.
          </p>
          <ButtonWrapper>
            <Button onClick={() => {
              window.location.href = `${process.env.FTECH_API_URL}/#/settings?loginemail=${msg}`
            }} className="default" title="LOGIN RepairSetup" />
          </ButtonWrapper>
        </>
      })
    }
  }

  const handleValidate = async (values) => {
    var formData = new FormData();
    formData.append('email', values.email)
    return fetch(process.env.FTECH_API_URL + '/validate', {
      method: 'POST',
      body: formData,
    })
  }

  return (
    <ThemeProvider theme={saasTheme}>
      <BackgroundImage
        Tag="section"
        style={{
          backgroundAttachment: "fixed",
          height: "100vh",
        }}
        fluid={Data.BackgroundImage.childImageSharp.fluid}
      >
        <ResetCSS />
        <GlobalStyle />
        <GlobalStyle1 />
        <ContentWrapper>
          <Sticky top={0} innerZ={1000} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <MainWrapper>
            <LoginWrapper>
              {state.state === 'form' &&
                <LoginDetails>
                  <Heading content="Let's Get Started" {...nameStyle} mb="10px" />
                  <Text content="Review your plan and create an account." {...descriptionStyle} />
                  <PlanInfoWrapper>
                    <Heading content={plan.name} {...nameStyle} mb={0} />
                    <Text content={plan.description} {...descriptionStyle} mb={0} />
                    <Heading content={plan.price} {...nameStyle} mb={0} />
                    <Text content={plan.priceLabel} {...descriptionStyle} mb={0} />
                  </PlanInfoWrapper>
                  <RegisterForm plan={plan} onRegister={handleRegister} onValidate={handleValidate} />
                </LoginDetails>}
              {state.state !== 'form' && <>
                <PlanInfoWrapper>
                  <Heading content={plan.name} {...nameStyle} mb={0} />
                  <Text content={plan.description} {...descriptionStyle} mb={0} />
                  <Heading content={plan.price} {...nameStyle} mb={0} />
                  <Text content={plan.priceLabel} {...descriptionStyle} mb={0} />
                </PlanInfoWrapper>
                {state.content}
              </>}
            </LoginWrapper>
            <AsideWrapper>
              <PriceInfo plan={plan} />
            </AsideWrapper>
          </MainWrapper>
        </ContentWrapper>
      </BackgroundImage>
    </ThemeProvider>
  )
}

const LoginWrapper = styled.div`
  width: 525px;
  margin: 80px auto;
  @media (max-width: 1050px) {
    padding: 0 10px 0;
  }

  .reusecore__input {
    /* margin-bottom: 30px; */
    &.is-material {
      &.is-focus {
        label {
          color: ${themeGet('colors.primary', '#10ac84')};
          top: -12px;
        }
        .highlight {
          background-color: ${themeGet('colors.primary', '#10ac84')};
        }
      }
    }

    label {
      font-weight: 400;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.6);
      top: 15px;
    }
  }

  .reusecore__button {
    background-color: transparent;
    &.default {
      background-color: ${themeGet('colors.primary', '#10ac84')};
      transition: all 0.3s ease;
      &:hover {
        box-shadow: 0px 9px 20px -5px rgba(16, 172, 132, 0.57);
      }
    }
  }

  .input_error {
    height: 40px;
    color: red;
    display: block;
  }

  .confirm_disable {
    pointer-events: none;
    opacity: 0.5;
  }
`

const LoginDetails = styled.section`
  margin-top: 50px;
`

const AsideWrapper = styled.aside`
  top: 0;
  right: 0;
  width: 480px;
  min-height: 100vh;
  background: linear-gradient(0deg, #008abe, #ffffff);
  @media (max-width: 770px) {
    display: none;
  }
`;

const PlanInfoWrapper = styled.section`
  border: 1px solid #d3d3d3;
  background-color: #f9f9f9;
  border-radius: 5px;
  display: none;
  margin-bottom: 32px;
  @media (max-width: 770px) {
    display: block;
  }
`

const MainWrapper = styled.main`
  display: flex;
  flex-direction: row;
  align-items: stretch;
`;

const MyPricingTable = styled(PricingTable)`
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  margin-top: 230px;
  padding: 45px 35px;
  @media (max-width: 1024px) {
    padding: 45px 15px;
    width: 85%;
  }
  @media (max-width: 767px) {
    padding: 45px 15px;
    width: 85%;
  }
  @media (max-width: 770px) {
    padding: 45px 15px;
    width: 95%;
  }
`

const PriceInfo = ({
  plan,
  nameStyle,
  descriptionStyle,
  priceStyle,
  priceLabelStyle,
  listContentStyle,
}) => (
    <MyPricingTable
      key={plan.name}
      freePlan={plan.freePlan}
      className="pricing_table"
    >
      <PricingHead>
        <Heading content={plan.name} {...nameStyle} />
        <Text
          content={plan.description}
          {...descriptionStyle}
        />
      </PricingHead>
      <PricingPrice>
        <Text content={plan.price} {...priceStyle} />
        <Text
          content={plan.priceLabel}
          {...priceLabelStyle}
        />
      </PricingPrice>
      <PricingList>
        {plan.listItems.map((item, index) => (
          <ListItem key={`pricing-table-list-${index}`}>
            <Icon
              icon={checkmark}
              className="price_list_icon"
              size={13}
            />
            <Text
              // content={item.content} 
              dangerouslySetInnerHTML={{ __html: item.content }}
              {...listContentStyle} />
          </ListItem>
        ))}
      </PricingList>
    </MyPricingTable>
  )

PriceInfo.propTypes = {
  nameStyle: PropTypes.object,
  descriptionStyle: PropTypes.object,
  priceStyle: PropTypes.object,
  priceLabelStyle: PropTypes.object,
  listContentStyle: PropTypes.object,
};

PriceInfo.defaultProps = {
  nameStyle: {
    fontSize: ['24px', '24px', '32px', '32px', '32px'],
    fontWeight: '500',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    textAlign: 'center',
    mb: '12px',
  },
  descriptionStyle: {
    fontSize: ['15px', '16px', '16px', '16px', '16px'],
    color: 'textColor',
    lineHeight: '1.75',
    textAlign: 'center',
    mb: '0',
  },
  priceStyle: {
    as: 'span',
    display: 'block',
    fontSize: ['36px', '36px', '40px', '40px', '40px'],
    color: 'headingColor',
    textAlign: 'center',
    mb: '5px',
    letterSpacing: '-0.025em',
  },
  priceLabelStyle: {
    fontSize: ['13px', '14px', '14px', '14px', '14px'],
    color: 'textColor',
    lineHeight: '1.75',
    textAlign: 'center',
    mb: '0',
  },
  listContentStyle: {
    fontSize: ['15px', '16px', '16px', '16px', '16px'],
    color: 'textColor',
    mb: '0',
  },
}
